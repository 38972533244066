import { useEffect, useState } from 'react';
import styles from './Footer.module.scss';
import { useTranslation } from 'react-i18next';
import { getWebshopFooter } from '../../../containers/cms-container/services';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';

const logoIcon = require("../../../assets/icons/logotyp_with_white_letters.svg").default;
const newsletterIcon = require("../../../assets/icons/newsletter.svg").default;
const przelewy24Icon = require("../../../assets/payments/przelewy24.svg").default;
const UEIcon = require("../../../assets/icons/UEIcon.svg").default;


const getFooter = (languageCode: string): any => getWebshopFooter(languageCode);

export default function Footer() {
    const { t, i18n } = useTranslation('translation');
    const [footer, setFooter] = useState<string>();
    const { language } = useParams();

    const getLanguageCode = (): string | null => localStorage.getItem("lang");

    useEffect(() => {
        const languageCode = getLanguageCode();
        if (languageCode) {
            getFooter(languageCode).then((response: any) => {
                setFooter(response.data);
            })
        }
    }, [])

    const renderSections = () => {
        if (!footer) return null;

        const sections = footer.split('<h3>');

        return sections.map((section, index) => {
            if (index === 0) return null;
            const [title, content] = section.split('</h3>');
            return (
                <div key={index} className={styles.footer_section}>
                    <h3>{title}</h3>
                    {parse(content)}
                </div>
            );
        });
    };

    return (
        <><div className={styles.container}>
            <div className={styles.decore_wrapper}></div>
            <div className={styles.newsletter}>
                <div className={styles.left_side}>
                    <img src={newsletterIcon} alt="Image" />
                    <div className={styles.description_container}>
                        <div className={styles.top}>
                            {t("footer.newsletterTop")}
                        </div>
                        <div className={styles.bottom}>
                            {t("footer.newsletterBottom")}
                        </div>
                    </div>
                </div>
                <div className={styles.right_side}>
                    <input type='text' placeholder={`${t("footer.yourEmail")}`} maxLength={60} />
                    <div className={styles.button}>
                        {t("footer.signup")}
                    </div>
                </div>
            </div>
            <div className={styles.logo_wrapper}>
                <img className={styles.logo} src={logoIcon} alt="Logo" />
            </div>
            <div className={styles.footer_container}>
                <div className={styles.columns}>{renderSections()}</div>
            </div>

            <div className={styles.line}></div>
            <div className={styles.copyright}>
                <div className={styles.left}>
                    <img src={przelewy24Icon} alt="Przelewy24" />
                </div>
                <div className={styles.right}>
                    <p className={styles.UEInfo}>{t("footer.UEInfo")}</p>
                    <img src={UEIcon} alt="UE" />
                    <p className={styles.copyright_label}>&copy; {new Date().getFullYear()} APPETIT</p>
                </div>

            </div>
        </div></>
    )
}
